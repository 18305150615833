 body 
 {
 	background-color: #FFFFFF;
 	background-image: url('../../../images/topnav.gif');
 	background-repeat: repeat-x;
 	margin: 0px 10px 20px 10px;
 }
 
a:link
{	color: #006;  text-decoration: underline;}

a:visited
{	color: #006; text-decoration: underline;}

a:active
{	color: #006; text-decoration: underline;}

a:hover
{	color: #960; text-decoration: underline;}

.table-container {
	max-height: 400px; /* Adjust the height according to your needs */
	overflow: auto;
  }

.pagetitle
{
	font-size: 93%;
	vertical-align: middle;
	font-weight: bold;
	font-family: Arial, Helvetica, sans-serif;
	color: #000;
}

.drop-down {
	white-space: nowrap;
	overflow: hidden; 
	text-overflow: ellipsis;
    width: 100px;
	border: 1px solid #ccc; /* Border */
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
	z-index: 1; 
	margin-left: 0px;
	cursor: pointer;
  }

  .drop-item{
  position: absolute;
  margin-left: 140px;
  transform: translate(-195px, 4px);
  cursor: pointer;
  margin-top: 25px;
    }
.logout-td{
    cursor: pointer;
}

.subTitle   
{
   	font-size: 20px;
   	vertical-align: middle;
   	font-weight: bold;
   	line-height: 45px;
   	font-family: Arial, Helvetica, sans-serif;
   	color: #4A4848;
}





.crumBox
{

	background-color: #F5F7EF;
	border-color: blue;
	padding: 5px 10px 5px 3px;
	border-width: 1px;
	border-style: dotted;
	font-size: 11px;
	color: #000;
	font-family: verdana, arial, sans serif;
	line-height: 12px;
	font-weight: 600;

}

.crumb
{
	font-size: 11px;
	color: #000;
	font-family: verdana, arial, sans serif;
	line-height: 12px;
	font-weight: 600;
}

A.crumb:link { color: #3944A4; text-decoration: underline;}

A.crumb:visited
{
	color: #666;
	text-decoration: underline;
}

A.crumb:active { color: #3944A4; text-decoration: underline;}

A.crumb:hover
{
	color: #20389C;
	text-decoration: none;
}

.helpLink
{
	font-size: 9px;
	color: #40414C;
	font-family: verdana, arial, sans serif;
	line-height: 18px;
	font-weight: 600;
}

a.helpLink:link { color: #40414C; text-decoration: none;}

a.helpLink:visited
{
	color: #300;
	text-decoration: none;
}

a.helpLink:active { color: #40414C; text-decoration: none;}

a.helpLink:hover
{
	color: #20389C;
	text-decoration: underline;
}

   .spacer   
   {
   	font-size: 5px;
   	color: transparent;
   	line-height: 5px;
   }
   
.logoutlink
{
	font-size: 9px;
	color: #40414C;
	font-family: verdana, arial, sans serif;
	line-height: 18px;
	font-weight: 600;
}

a.logoutlink:link { color: #40414C; text-decoration: none;}

a.logoutlink:visited
{
	color: #300;
	text-decoration: none;
}

a.logoutlink:active { color: #40414C; text-decoration: none;}

a.logoutlink:hover
{
	color: #20389C;
	text-decoration: underline;
}
   
.confirmation
{
	background-color: #FFF;
	border-color: #E8AD46;
	padding: 5px 5px 5px 5px;
	border-width: 1px;
	border-style: solid;
	font-size: 12px;
	color: #000;
	font-family: verdana, arial, sans serif;
	line-height: 15px;
	font-weight: 400;
}

.liveURL
{
	font-size: 10px;
	color: #D18F1D;
	font-family: verdana, arial, sans serif;
	line-height: 11px;
	font-weight: 800;
}

   .myTable th   
   {
   	font-family: Arial, Helvetica, sans-serif;
   	font-size: 9pt;
   	font-weight: bold;
   	background-color: #CCCFF3;
   	padding: 3 1 3 1;
   }
   
.cellWhite
{
	font-family: Arial, Helvetica, sans-serif;
	font-size: 8pt;
	font-weight: bold;
	background-color: white;
	padding: 3 1 3 1;
}

.cellGreen
{
	font-family: Arial, Helvetica, sans-serif;
	font-size: 8pt;
	font-weight: bold;
	background-color: #F5F7EF;
	padding: 3 1 3 1;
}

.helpGreen
{
	font-family: Arial, Helvetica, sans-serif;
	font-size: 10pt;
	font-weight: bold;
	background-color: #F5F7EF;
	padding: 3 1 3 1;
	background-image: url('../../../images/icon_help_large.gif');
	background-repeat: no-repeat;
}

.helpSubHead
{
	background-color: #D9DBF6;
	padding: 5px 10px 5px 5px;
	font-size: 13px;
	color: #000;
	font-family: verdana, arial, sans serif;
	line-height: 16px;
	font-weight: 600;
}

.helpContent
{
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	font-weight: 400;
	padding: 3 1 3 1;
	line-height: 16px;
}


.myTable
{
	background-color: white;
	border-collapse: collapse;
	 /* #AFAFAF; */
}

.formButtonStop    { font-size: 64%; vertical-align: middle; font-family : Arial, Helvetica, sans-serif; background-color: #992D2F; color: #FFFFFF; }

.formButtonDownload    { font-size: 64%; vertical-align: middle; font-family : Arial, Helvetica, sans-serif; background-color: green; color: #FFFFFF; }



   form   {  font-family: Arial, Helvetica, sans-serif; font-size: 10pt}
   h1   {  font-family: Verdana, Arial, Helvetica, sans-serif; font-size: 16pt; font-weight: bold}
   .info    {  font-family: Arial, Helvetica, sans-serif; font-size: 10pt; text-decoration: none; color: black}


.copyright
{
	font-size: 11px;
	vertical-align: left;
	color: #000;
	font-family: verdana, arial, sans serif;
}

.formButtonGo
{
	border: none;
    background-color: white;
}
.checkboxalign{
	text-align: center;
	/*background-color: white;*/
}
.folderpointer
{
	cursor: pointer;
}

/* MODAL */
/* Background effect. It covers the entire site */
.modal {
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Overlay effect: translucent background: black w/ partial opacity */
    z-index: 1; /* Overlay effect: positioned over other containers */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    position: fixed; /* Fix position on the top-left corner*/
    top: 0;
    left: 0;
    overflow: auto; /* Enable scroll if needed */
    padding-top: 80px; /* Location of the content container */
	font-family: Arial, Helvetica, sans-serif;
	font-size: 10pt;
	/* pointer-events: none;  */
	/* background-color: #FFF; */
	/* background-image: url('../../../images/topnav.gif');
	background-repeat: repeat-x;
	margin: 0px 0px 0px 0px; */
}
/* Modal content */
.modal-content {
    background-color: white;
    /*width: 70%; /* Width in proportion to its parent container*/
    max-width: 640px; /* Max width where it stops expanding */
    height: 500px; /*70%; /* Height in proportion to its parent container */
    margin: auto; /* Auto margin according to the element width */
    /* padding: 10px; */
    border: 1px solid black;
    border-radius: 20px; /* Optional. Rounds container corners */
	user-select: none;
}
/* Close button */
.close {
   color: #aaaaaa;
   float: right; /* Positioned to the right of the parent container whichever size it is */
   font-size: 25px;
   font-weight: bold;
   /* width:5%; */
}
.close:hover, 
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
.spinner{
    position: absolute;
    height: 100px;
    width: 100px;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    background-size: 100%;
}

.users-mi-icon {
	height: 35px !important;
    width: 50px !important;
    background-color: #E9EAED;
    border-radius: 10px;
    border: 1px solid;
}

.unzip {
	position:absolute; 
	left:0px; 
	top:35px; 
	width:92px; 
	height:207px; 
	z-index:0; 
	background-color: #E9EAEC; 
	/* layer-background-color: #E9EAEC;  */
	border: 1px none #000000;
}

.unzip-table {
	position:absolute; 
	left:0px; 
	top:45px; 
	width:600px; 
	height:120px; 
	z-index:1;
}

.unzip-formButtonGo
{
	font-size: 11px;
	vertical-align: middle;
	font-family: Arial, Helvetica, sans-serif;
	background-color: #407447;
	color: #FFF;
}

.unzip-formButton
{
	font-size: 11px;
	vertical-align: middle;
	font-family: Arial, Helvetica, sans-serif;
	background-color: #DDD;
	color: #000;
}

.delete-selected
{
	cursor: pointer;
	color: darkblue;
	text-decoration: underline;
}

.table-container-bulkdownload {
	max-height: 380px; /* Adjust the height according to your needs */
	overflow: auto;
  }